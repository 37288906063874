<template>
  <main>
	 <div id="app">
		<div id="wrapper">
		  <Navigation :highlightSupport="highlightSupport" v-if="isLogin"/>
		  <div id="content-wrapper" class="d-flex flex-column" :class="isLogin ? 'content-wrapper-nav-fixed': ''">
			 <div id="content" class="mobile">
				<Head @support-clicked="supportClicked" v-if="isLogin"/>
				<div class="container-fluid"> 
				  <router-view/>
				</div>
			 </div>
		  </div>
		</div>
	 </div>
  </main>
</template>

<style scoped>
#content{
  background : #f8f8f8;
}
</style>

<script>
import { viewLogger } from '@/lib/utils'
import { User } from '@/services/User'
import {Auth} from "@/services/Auth";
import {Hotel} from "@/services/Hotel";

export default {
  data() {
	 return {
		updateID: null,
		updatePeriod: 20000,
		disableHighlightPeriod: 3000,
		user: null, // Данные пользователя
		highlightSupport: false // Подсветка поддержки
	 }
  },
  components: {
	 // Ленивая загрузка компонентов Navigation и Head
	 Navigation: () => import('./components/Nav'),
	 Head: () => import('./components/Header')
  },
  computed: {
	 // Активный пользователь
	 userActive() {
		return this.$root.user
	 },
   isLogin(){
     return !!this.user
   }
  },
  methods: {
	 // Метод для обработки клика по поддержке
	 supportClicked() {
		this.highlightSupport = true
		// Отключаем подсветку через 3 секунды
		setTimeout( () => {
		  this.highlightSupport = false
		}, this.disableHighlightPeriod )
	 },
	 // Метод для обновления данных пользователя
	 updateUserData() {
		if ( this.$root.user ) {
		  User.get( { id: this.$root.user.id }, { cache: false } ).then( user => user && ( this.$root.user = user ) )
		}
	 }
  },
  mounted() {
   // Периодическое обновление данных пользователя
	 this.updateUserData()
	 this.updateID = setInterval( this.updateUserData, this.updatePeriod ) // Интервал обновления каждые 20 секунд
	 
	 // Обработка события установки пользователя
	 this.$root.$on( 'user', user => {
     this.$root.user = user
     this.user = user
     User.save( user )
   } )
    
   // Обработка события самого логина пользователя
	 this.$root.$on( 'user:login', user => {
     this.$router.push( '/dashboard' )
   } )
	 
	 // Обработка события выхода пользователя
    this.$root.$on('user:logout', () => {
      this.$root.user = null
      this.user = null
      User.clear()
      Auth.clear()
      Hotel.clear()
      if (this.$route.path !== '/login') {
        this.$router.push('/login')
      }
    })
	 
	 // Получение данных пользователя из localStorage при загрузке
	 const user = User.getLocalUser()
	 if ( user ) {
		this.$root.$emit( 'user', user )
	 }
  },
  destroyed() { // Остановка обновления данных пользователя
	 clearInterval( this.updateID )
  },
  beforeMount() {
	 viewLogger( true, 'App', this )
  },
  beforeDestroy() {
	 viewLogger( false, 'App', this )
  }
}
</script>
