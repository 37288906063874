import { ApiClient } from '@/services/Api'

/**
 * Класс `AuthClient` для выполнения операций авторизации и регистрации через API.
 *
 * Методы:
 * - `login({ email: string, password: string }): Promise<Object|null>`: Выполняет авторизацию пользователя.
 * - `register({ name: string, phone: string, email: string, inn: string, object_name: string }):
 * Promise<Object|null>`: Регистрирует нового пользователя.
 * - `save(token: string): void`: Сохраняет токен авторизации в localStorage.
 * - `clear(): void`: Удаляет токен авторизации из localStorage.
 *
 * Приватные поля:
 * - `#api: ApiClient`: Экземпляр клиента API для выполнения HTTP-запросов.
 */
class AuthClient {
    // Приватное поле, содержащее экземпляр ApiClient
    #api = new ApiClient()
    
    /**
     * Метод для авторизации пользователя
     * @param {string} params.email - Email пользователя
     * @param {string} params.password - Пароль пользователя
     * @returns {Promise<Object|null>} - Возвращает данные пользователя или null
     */
    login( params = { email: '', password: '' } ) {
        // Возвращаем новый промис
        return new Promise( async ( resolve, reject ) => {
            // Проверяем, переданы ли email и пароль
            if ( !params.email || !params.password ) {
                console.error( 'Ошибка авторизации. Не передан email или password' )
                return resolve( null )
            }
            
            this.#api.fetch( {
                    method: 'post',
                    url: '/auth',
                    data: params
                } )
                .then( ( { status, data } ) => {
                    // Если статус успешен, сохраняем токен и возвращаем данные
                    if ( status ) {
                        this.save( data.token )
                        return resolve( data )
                    }
                    // Если статус не успешен, возвращаем null
                    return resolve( null )
                } )
                .catch( e => {
                    // Обрабатываем ошибку и передаем ее в reject
                    console.error( 'Ошибка авторизации пользователя', e )
                    return reject( e.data )
                } )
        } )
    }
    
    /**
     * Метод для регистрации нового пользователя
     * @param {Object} param - Объект с данными для регистрации
     * @param {string} param.name - Имя пользователя
     * @param {string} param.phone - Телефон пользователя
     * @param {string} param.email - Email пользователя
     * @param {string} param.inn - ИНН
     * @param {string} param.object_name - Название объекта
     * @returns {Promise<Object|null>} - Возвращает данные пользователя или null
     */
    register( { name, phone, email, inn, object_name } ) {
        // Возвращаем новый промис
        return new Promise( ( resolve, reject ) => {
            // Проверяем, переданы ли все необходимые данные
            if ( !name || !phone || !email || !inn || !object_name ) {
                console.error( 'Ошибка регистрации. Не передан один из параметров: name, phone, email, inn, object_name' )
                return resolve( null )
            }
            
            // Выполняем запрос на регистрацию
            this.#api.fetch( {
                    method: 'post',
                    url: '/applications/new',
                    data: { name, phone, email, inn, object_name }
                } )
                .then( ( { status, data } ) => {
                    // Если статус успешен, сохраняем токен и возвращаем данные
                    if ( status ) {
                        this.save( data.token )
                        return resolve( data )
                    }
                    // Если статус не успешен, возвращаем null
                    return resolve( null )
                } )
                .catch( e => {
                    // Обрабатываем ошибку и передаем ее в reject
                    console.error( 'Ошибка регистрации пользователя', e )
                    return reject( e.response.data )
                } )
        } )
    }
    
    /**
     * Метод для сохранения токена в localStorage
     * @param {string} token - Токен авторизации
     */
    save( token ) {
        localStorage.setItem( 'token', token )
    }
    
    /**
     * Метод для удаления токена из localStorage
     */
    clear() {
        localStorage.removeItem( 'token' )
    }
}

/**
 * Экземпляр класса `AuthClient` для выполнения операций авторизации и регистрации через API.
 *
 * @type {AuthClient}
 *
 * Методы:
 * - `login({ email: string, password: string }): Promise<Object|null>`: Выполняет авторизацию пользователя.
 * - `register({ name: string, phone: string, email: string, inn: string, object_name: string }):
 * Promise<Object|null>`: Регистрирует нового пользователя.
 * - `save(token: string): void`: Сохраняет токен авторизации в localStorage.
 * - `clear(): void`: Удаляет токен авторизации из localStorage.
 *
 * Приватные поля:
 * - `#api: ApiClient`: Экземпляр клиента API для выполнения HTTP-запросов.
 */
export const Auth = new AuthClient()
