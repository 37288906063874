import VueRouter from 'vue-router'

//TODO Make dynamic routes
const routes = [
  {
    path: '/',
    name: 'main',
    blank: true,
    meta: {
      name: 'Вход',
      requiresAuth: false,
      permissions: []
    },
    component: () => import('../views/Pages/Login/Login.vue')
  },
  {
    path: '/login',
    name: 'login',
    blank: true,
    meta: {
      name: 'Вход',
      requiresAuth: false,
      permissions: []
    },
    component: () => import('../views/Pages/Login/Login.vue')
  },
  {
    path: '/registration',
    name: 'registration',
    blank: true,
    meta: {
      name: 'Регистрация',
      requiresAuth: false,
      permissions: []
    },
    component: () => import('../views/Pages/Register/Register.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      name: 'Главная',
      requiresAuth: true,
      permissions: []
    },
    component: () => import('../views/Pages/Dashboard/Dashboard.vue')
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      name: 'Сотрудники',
      requiresAuth: true,
      permissions: ['vendor', 'administrator', 'support']
    },
    component: () => import('../views/Pages/Users/Users.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    meta: {
      name: 'Заказы',
      requiresAuth: true,
      permissions: ['vendor', 'administrator', 'support', 'manager']
    },
    component: () => import('../views/Pages/Orders/Orders.vue')
  },
  {
    path: '/timeslots',
    name: 'timeslots',
    meta: {
      name: 'Тайм - слоты',
      requiresAuth: true,
      permissions: ['vendor', 'administrator', 'support', 'manager']
    },
    component: () => import('../views/Pages/Timeslots/Timeslots.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {
      name: 'Настройка объекта',
      requiresAuth: true,
      permissions: ['administrator', 'support']
    },
    component: () => import('../views/Pages/Settings/Settings.vue')
  },
  {
    path: '/settings_system',
    name: 'settings_system',
    meta: {
      name: 'Настройка системы',
      requiresAuth: true,
      permissions: ['vendor']
    },
    component: () => import('../views/Pages/SettingsSystem/SettingsSystem.vue')
  },
  {
    path: '/objects',
    name: 'objects',
    meta: {
      name: 'Объекты',
      requiresAuth: true,
      permissions: ['vendor', 'support']
    },
    component: () => import('../views/Pages/Hotels/Hotels.vue')
  },
  {
    path: '/categories',
    name: 'categories',
    meta: {
      name: 'Категории',
      requiresAuth: true,
      permissions: ['vendor', 'administrator', 'support', 'manager']
    },
    component: () => import('../views/Pages/Categories/Categories.vue')
  },
  {
    path: '/guests',
    name: 'guests',
    meta: {
      name: 'Гости',
      requiresAuth: true,
      permissions: ['vendor']
    },
    component: () => import('../views/Pages/Guests/Guests.vue')
  },
  {
    path: '/items',
    name: 'items',
    meta: {
      name: 'Позиции',
      requiresAuth: true,
      permissions: ['vendor', 'administrator', 'support', 'manager']
    },
    component: () => import('../views/Pages/Items/Items.vue')
  },
  {
    path: '/request',
    name: 'Заявки',
    meta: {
      name: 'Заявки',
      requiresAuth: true,
      permissions: ['vendor']
    },
    component: () => import('../views/Pages/Proposal/Proposal.vue')
  },
  {
    path: '/error',
    name: '404 - Страница не найдена',
    meta: {
      name: '404 ошибка',
      requiresAuth: true,
      permissions: []
    },
    component: () => import('../views/Pages/Error/Error.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'pageNotFound',
    meta: {
      name: 'Страница не найдена',
      requiresAuth: true,
      permissions: []
    },
    component: () => import('../views/Pages/Error/Error.vue')
  }
]

const updateRouter = ({routes, currentRouter}) => {
  const {matcher, options} = new VueRouter({
    routes
  })

  currentRouter.matcher = matcher;
  currentRouter.options = options;

  routes.forEach(route => {
    currentRouter.addRoute(route);
  })

  return currentRouter
}
const privateRoutesByUser = ({role, privateRoutes}) => {
  return privateRoutes.filter(({meta}) =>
    meta.permissions.includes(role) || meta.permissions.length === 0
  );
}

const publicRoutes = routes.filter(route => !route.meta.requiresAuth)
const privateRoutes = routes.filter(route => route.meta.requiresAuth)
const getRoutesForUser = ({user}) => {
  if (!user) {
    return publicRoutes
  } else {
    return privateRoutesByUser({
      role: user.group,
      privateRoutes
    })
  }
}
const setupRoutesByUser = ({user, currentRouter}) => {
  const routes = getRoutesForUser({user})
  return updateRouter({
    routes,
    currentRouter
  })
}

export {
  routes,
  publicRoutes,
  privateRoutes,
  getRoutesForUser,
  setupRoutesByUser
}