import {User} from '@/services/User'
import Vue from 'vue'
import VueRouter from 'vue-router'
import {routes} from "@/router/config";

Vue.use(VueRouter)

const router = new VueRouter({
    routes,
})

/**
 * Перед каждым переходом маршрута проверяет, авторизован ли пользователь.
 * Если пользователь авторизован, перенаправляет на дашборд при переходе на страницу логина или регистрации.
 * Если не авторизован, перенаправляет на страницу логина при попытке перейти на защищенные маршруты.
 *
 * @param {Object} to - Маршрут, на который переходит пользователь.
 * @param {Object} from - Маршрут, с которого уходит пользователь.
 * @param {Function} next - Функция, которая завершает навигационный процесс.
 */
router.beforeEach((to, from, next) => {
    // Получаем объект пользователя из приложения или локального хранилища.
    let user = router.app.user || User.getLocalUser() || null
    const isAuthPage = ['login', 'registration', 'main'].includes(to.name)

    
    // Определяем, авторизован ли пользователь.
    const isLogged = Boolean(user)
    // logger( '[Router.index]', 'Auth data:', {
    // 	isLogged,
    // 	to: to.name,
    // 	user
    // } )
    if (isLogged) {
        if (!isAuthPage && to.fullPath === from.fullPath) {
            return;
        }
    } else {
        if (!isAuthPage) {
            return next({path: '/login'})
        }
    }
    // Проверяем, если пользователь пытается перейти на страницы логина или регистрации.
    if (isAuthPage) {
        // Если пользователь авторизован, перенаправляем на дашборд.
        if (isLogged) {
            return next({path: '/dashboard'})
        }
    } else {
        // Если пользователь не авторизован, перенаправляем на страницу логина.
        if (!isLogged) {
            return next({path: '/login'})
        }
    }

    // Завершаем переход на целевой маршрут.
    next()
})
export default router
